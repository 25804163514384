import Vue from 'vue'
import Vuex from 'vuex'
import { Toast } from 'vant'
Vue.use(Vuex);
// 创建一个新的 store 实例
const store = new Vuex.Store({
    state: {
        mindate:new Date(2024,8,1),
        datas:''
    },
    getters: {
        getString(state) {
            // 固定参数state
            // return state.token + '你好'
        },
    },
    mutations: {
        xs(state,datas){
            state.datas = datas;
            Toast(state.datas);
        }
    },
    actions: {
        
    },
    modules: {}
})
export default store