<template>
    <div class='oper_vill' style="font-size: 1.5rem;padding: 1rem;">
        <div v-if="sure" class="fl1 loads">
            <div class="xql"><van-loading size="26px" color="#fff" type="spinner" vertical>加载中...</van-loading></div>
        </div>
        <div style="margin-bottom: 1rem;position: relative;" class="op fl1">
            <van-cell title="请选择日期" :value="dates4" @click="show = true" />
            <van-calendar v-model="show" :show-confirm="false" :max-range="31" :min-date="minDate" :max-date="maxDate"
                type="range" @confirm="onConfirms" :allow-same-day="true"/>
        </div>
        <div class="op_list">
            <div style="margin-bottom: .6rem;justify-content: space-between;" class="fl1">周期统计 <div
                    style="color: #999;">({{ sta }} / {{ end }})
                </div>
            </div>
            <div class="lh fl1">
                <div class="w50 fl1">钱包充值：<div style="color: #333">{{ m_wallet }}元</div> </div>
                <div class="w50 fl1">月卡充值：<div style="color: #333">{{ m_month }}元</div> </div>
            </div>
            <div class="lh fl1">
                <div class="w50 fl1">单次充电：<div style="color: #333">{{ m_single }}元</div> </div>
                <div class="w50 wn fl1">电卡充值：<div style="color: #333">{{ m_card }}元</div> </div>
            </div>
            <div class="lh fl1">
                <div class="w50">费&emsp;&emsp;率：{{ rate }}</div>
                <div class="w50 fl1">合&emsp;&emsp;计：<div style="color: #333">{{ all }}元</div> </div>
            </div>
            <div class="lh fl1">
                <div class="fl1">实际收入：<div style="color: #333">{{ income }}元</div> &nbsp;<img src="../../assets/wen.png" style="width: 1.6rem;" @click="wens()"></div>
            </div>
            <div class="line"></div>
            <div class="lh fl1">线下充值：<div style="color: #333">{{ offline }}元</div> (无交易记录不算实际收入)</div>
        </div>
        <div class="cock_bt" ref="myEchart" id="main"></div>
        <div class="op_list" style="margin-top: .6rem;">
            <div style="margin-bottom: .6rem;">商创收益</div>
            <div class="fl1" style="justify-content: space-between;">
                <div class="lh fl1">
                    <div class="fl1">安心充（单）：<div style="color: #333">{{ sc1 }}元</div>&nbsp;<img src="../../assets/wen.png" style="width: 1.6rem;" @click="wens1()"></div>
                </div>
                <div style="color: #1260FC;" class="fl1" @click="display1 = true">明细 <img src="../../assets/you1.png" style="margin-left: .4rem;height: 1.2rem;"></div>
            </div>
            <div class="lh fl1" v-if="sc2 == 0">增值积分：<div style="color: #333">累计{{ sc3 }}分</div>(未兑换{{ sc4 }}分)</div>
        </div>
        <div style="height: 5rem;"></div>
        <div class="cha_xq ind_g" v-show="display1" @click.self="display1 = false">
            <div class="cha_xq_con" style="padding: 2rem 2rem 0;">
                <div class="xq_con_s">
                    <div style="font-size: 1.6rem;font-weight: 600;">累积安心充（单）：{{ sc1 }}元</div>
                    <div></div>
                    <img src="../../assets/cha.png" style="width: 1.2rem;" @click="display1 = false">
                </div>
                <div class="line" style="margin: 1rem 0 !important;"></div>
                <div class="G2"  style="height: 28rem;overflow-y: scroll;margin: 1rem 0;">
                    <div v-for="(item,index) in list1" :key="index">
                        <div class="fl1" style="justify-content: space-between;margin-top: 1rem;">
                            <div>&emsp;{{ item.day }}</div>
                            <div style="width: 40%;">{{ item.money }}元</div>
                        </div>
                    </div>
                    <img src="../../assets/ww.png" style="width: 100%;" v-if="list1.length ==0">
                </div>
                <div style="margin: 1rem 0 2rem;font-size: 1.6rem;">
                    <button class="opBtn"  @click="display1 = false">关闭</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant"
import bus from "../../store/bus"
import { getOday } from "@/api/oper/index"
import { getSc, getScs } from "@/api/sys"
import scrollTop from "@/components/scroll.vue"
import searchVue from "@/components/search.vue"
export default {
    components: { scrollTop ,searchVue},
    data() {
        return {
            abc:false,
            ven:'请选择',
            myEchart: null,
            ven_id:'',
            dis:'请选择',
            dis_id:'',
            shows: false,
            arr: null,
            sc1: null,
            sc2: null,
            sc3: null,
            sc4: null,
            sta: null,
            end: null,
            m_single: '',
            m_card: '',
            m_month: '',
            all: '',
            page: 1,
            m_wallet: '',
            show: false,
            show1: false,
            // 列表触底
            loading: false,
            nos: false,
            finished: false,
            refreshing: false,
            list: [],
            list1: [],
            minDate: this.$store.state.mindate,
            add:null,
            maxDate: new Date(),
            dates1: '',
            dates2: '',
            dates3: '',
            dates4: '',
            sure: true,
            display: false,
            display1: false,
            value2: 0,
            a: 0,
            b: 0,
            c: 0,
            d: 0,
            e: 0,
            value3: '',
            id: '',
            value5: '',
            offline: null,
            rate: '',
            income: null,
            date: '',
            date1: '',
            value4: '',
            option1: [
                { text: '请选择', value: 0 },
            ],
            option2: [
                { text: '请选择', value: 0 },
            ]
        };
    },
    //方法集合
    methods: {
        xdis(){
            if (this.ven_id) {
                this.abc = true;
                this.data = 'district';
            }else{
                Toast('请先选择运营商');
                return;
            }
        },
        StrToNum(str) {  
            if (str == 0) {
                return;
            }
            const regex = /^(\d+(\.\d+)?)(万|亿|)?$/;  
            const match = str.match(regex);  
            if (!match) {  
                throw new Error('无效的字符串格式');  
            }  
            const numberStr = match[1]; // 提取数字部分  
            const unit = match[3]; // 提取单位
            let number;
            if (unit == '万') {
                number = (parseFloat(numberStr) * 10000).toFixed(0);   
            }else{
                number = parseFloat(numberStr);
            }
            return number;  
        },
        async get() {
            let data = await getOday({
                startdate: this.dates1,
                enddate: this.dates2,
            });
            if (data.data.code == 1) {
                let daTa = data.data.data.json_arr;
                this.sta = data.data.data.startdate;
                this.end = data.data.data.enddate;
                this.all = daTa.all;
                this.rate = daTa.rate;
                this.income = daTa.income;
                this.offline = daTa.offline;
                this.m_single = daTa.single;
                this.m_card = daTa.card;
                this.m_month = daTa.month;
                this.m_wallet = daTa.wallet;
                this.sure = false;
                this.a = this.StrToNum(this.m_single.toString());
                this.b = this.StrToNum(this.m_wallet.toString());
                this.c = this.StrToNum(this.m_month.toString());
                this.d = this.StrToNum(this.m_card.toString());
                this.e = this.StrToNum(this.offline.toString());
                if (this.m_card == 0 && this.m_single ==0 && this.m_month == 0 && this.m_wallet == 0 && this.offline == 0) {
                    this.$refs.myEchart.style.display = 'none';
                }else{
                    this.$refs.myEchart.style.display = 'block';
                    this.myEchart = await this.$echarts.init(this.$refs.myEchart);
                    let op = {
                        title: [{
                            text: '周期统计占比图',
                            left: 8,
                            top: 8,
                            textStyle: { fontSize: 15, color: "#333333", fontWeight:'none'}
                        }],
                        tooltip: {
                            trigger: 'item',
                            confine: true,
                            formatter: function (params) {
                                return params.data.value
                            }
                        },
                        legend: {
                            orient: 'horizontal',
                            bottom: '2%',
                            itemWidth: 15,
                            textStyle: {
                                fontSize: '14',
                            }
                        },
                        series: [{
                                type: 'pie',
                                radius: ['40%', '60%'],
                                center: ['50%', '20%'],
                                top: "27%",
                                data: [
                                { value: this.a, name: '单次充电   ' + this.m_single},
                                { value: this.b, name: '钱包充值   ' + this.m_wallet },
                                { value: this.c, name: '月卡充值   ' + this.m_month +' '},
                                { value: this.d, name: '线上电卡充值   ' + this.m_card + ' '},
                                { value: this.e, name: '线下充值   ' + this.offline }
                            ]}
                        ],
                        color:['#FF8C90','#56A2F2','#9B55C9','#41EAFA','#FFB361']
                    };
                    this.myEchart.setOption(op);
                    window.onresize = this.myEchart.resize;
                }
            }
            let datas = await getSc({token:localStorage.getItem('token'),vender_id:this.id});
            this.sc1 = datas.data.data.dc_single_money;
            this.sc2 = datas.data.data.is_close;
            this.sc3 = datas.data.data.total_score;
            this.sc4 = datas.data.data.total_unpay_score;
            let datas1 = await getScs({token:localStorage.getItem('token'),vender_id:this.id});
            this.list1 = datas1.data.data.list;

        },
        formatDate(date) {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        },
        formatDate1(date) {
            return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
        },
        onConfirm(date) {
            this.shows = false;
            this.date = this.formatDate(date);
        },
        onConfirm1(date) {
            this.show1 = false;
            this.date1 = this.formatDate(date);
        },
        onConfirms(date) {
            const [start, end] = date;
            this.show = false;
            this.dates1 = this.formatDate(start);
            this.dates2 = this.formatDate(end);
            this.dates3 = `${this.formatDate(start)} / ${this.formatDate(end)}`;
            this.dates4 = `${this.formatDate(start)} / ${this.formatDate(end)}`;
            this.get();
        },
        wens(){
            Toast('不算赠送');
        },
        wens1(){
            Toast('T+1 到账');
        }
    },
    created() {
        bus.$on("item", (data) => {
            this.ven = data.vender_name;
            this.ven_id = data.vender_id;
        })
        bus.$on("item1", (data) => {
            this.dis = data.district_name;
            this.dis_id = data.district_id;
        })
        bus.$on("abc", (data) => {
            this.abc = data;
        });
        bus.$on("id", (data) => {
            this.id = data;
        });
        this.get();
    }
}
</script>
<style lang='less'>
.oper_vill {
    .opBtn{
        border-radius: .6rem;
        background: #1260FC;
        font-size: 1.6rem;
        width: 100%;
        color: #fff;
        height: 4rem;
    }
    .s1 {
        color: #1260FC;
        margin-bottom: .6rem;
        font-size: 1.5rem !important;
    }
    .cock_bt {
        height: 31rem;
        display: flex;
        margin-top: .6rem;
        background-color: #fff;
        border-radius: .6rem;
        align-items: center;
        font-size: 1.5rem;
    }
    .num_s {
        justify-content: space-between;
        width: 100%;
        
    }
    .ind_g{
        .G2::-webkit-scrollbar {
            display: none
        }
    }
    .op>.van-cell {
        justify-content: space-between;
        // padding-right: 6rem;
        align-items: center;
        .van-cell__title{
            color: #1260FC !important;
        }
        .van-cell__value{
            color: #999;
        }
        .van-cell__title,
        .van-cell__value {
            -webkit-box-flex: none;
            -webkit-flex: none;
            font-size: 1.5rem;
            flex: none;
        }
    }

    .line {
        margin: .6rem 0 !important;
    }

    .lh {
        line-height: 1.7;
        color: #999;
    }

    .list_s {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        
        color: #999;
    }

    .cha_xq {
        width: calc(100% - 4rem);
        height: 100%;
        position: fixed;
        padding: 0 2rem;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 200;
        background: rgba(0, 0, 0, 0.5);

        .inp {
            position: relative;
            margin-bottom: 1rem;

            .cha_img {
                position: absolute;
                right: 3rem;
                top: .9rem;
            }
        }

        .van-button--plain.van-button--info {
            height: 3rem;
        }

        .van-calendar__selected-day {
            background-color: #1260FC;
        }

        .van-button--danger {
            background-color: #1260FC;
            border: 1px solid #1260FC;
        }

        .f1 {
            .van-cell__value {
                background-color: #f7f7f7;
                border-radius: .6rem;
                text-align: center;
            }

            .van-overlay {
                background-color: transparent;
            }

            .van-cell {
                padding: 0;
                font-size: 1.5rem;
                line-height: 3.6rem !important;
            }

            .van-cell__title {
                text-align: left !important;
                flex: 0.39;
            }

            .van-cell::after {
                display: none;
            }
        }

        .cha_xq_con {
            width: 100%;
            background-color: #fff;
            border-radius: 1rem 1rem 0 0;
            padding: 1.4rem 3rem 0rem;
            border-radius: .6rem;

            .xq_con_s {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .G2 {
            .non {
                .van-cell__value {
                    display: none;
                }
            }

            .fl_inp {
                height: 4rem;
                width: 32%;
                border-radius: 6px;
                padding-left: 0.4rem;
                font-size: 1.5rem;
                background-color: #F7F7F7;
            }

            .van-cell__title {
                text-align: center;
            }

            .van-button--normal {
                padding: 0 1rem;
                font-size: 1.6rem;
            }

            .f1 {
                .van-cell {
                    padding: 0;
                }

                .van-cell__title {
                    text-align: left;
                    flex: 0.39;
                }

                .van-cell::after {
                    display: none;
                }
            }

            .xq_fl {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.5rem;
                margin-bottom: 1.4rem;

                div {
                    color: #716F76;
                }

                .van-calendar__footer {
                    .van-button__content {
                        color: #fff;
                    }
                }

                .ss {
                    .van-dropdown-menu__title {
                        margin-left: 7rem;
                    }

                    .van-cell__value {
                        border-radius: 0 !important;
                    }

                    .van-search .van-cell {
                        padding: 0 !important;
                        background-color: #fff;
                    }
                }

                .xq_f {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                }

                .van-cell__value {
                    background-color: #f7f7f7;
                    border-radius: .4rem;
                    font-size: 1.5rem;
                    color: black;
                    text-align: center;
                }

                .van-overlay {
                    background-color: transparent;
                }

                .van-search {
                    position: relative;
                }

                .van-field__left-icon {
                    display: none;
                }

                .van-search__content {
                    background-color: transparent;
                    position: absolute;
                    z-index: 99;
                    padding-left: 0 !important;
                    top: 0rem;
                    height: 3.6rem;
                    left: 0;

                    .van-search,
                    .van-cell {
                        padding: 0 !important;
                        border-radius: 0 !important;
                    }

                    .van-field__control {
                        line-height: 3.6rem;
                        padding-left: 1rem;
                    }
                }

                .van-dropdown-menu__bar {
                    height: 3.6rem;
                    background-color: #F7F7F7;
                    border-radius: 6px;
                    box-shadow: none;
                }

                .van-dropdown-menu__title {
                    color: #666;
                }

                .van-popup--top {
                    left: 33%;
                    width: 55%;
                    touch-action: pan-y;
                    overflow-y: scroll;
                    border-radius: .6rem;
                }

                .van-dropdown-menu__title--active,
                .van-dropdown-item__option--active,
                .van-dropdown-item__option--active .van-dropdown-item__icon {
                    color: #1260FC;

                    .van-cell__value {
                        display: none;
                    }
                }
            }
        }
    }

    .van-tabs__wrap {
        position: fixed;
        width: 100%;
        z-index: 100;
        top: 0;
        left: 0;
    }

    .van-tabs__content {
        padding: 1rem 1rem 0 1rem;
    }

    .oper_nav {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0rem;
        right: 4.5rem;
    }

    .oper_img1 {
        width: 4rem;
        height: 4rem;
    }

    .van-calendar__day--middle {
        color: #1260FC;
    }

    .van-cell {
        border-radius: 1rem;
    }

    .van-calendar__day--end,
    .van-calendar__day--multiple-middle,
    .van-calendar__day--multiple-selected,
    .van-calendar__day--start,
    .van-calendar__day--start-end {
        background-color: #1260FC;
        border-radius: .6rem;
    }

    .op_list {
        padding: 1rem 1.5rem;
        border-radius: .4rem;
        margin-bottom: .6rem;
        background-color: #fff;
    }
}
</style>