<template>
    <div class="loinx" :style="{height:need == 2 ? 'auto' : '100vh'}">
        <div class="loinx_top fl1">
            <img :src="url" class="loinx_t_img">
            <div>
                <div class="fl1 wn">
                    <div style="font-size: 1.6rem;">{{ name }}</div>
                    <div class="loinx_bt">正常状态</div>
                </div>
                <p v-if="phone">{{ phone }}</p>
                <p v-else>暂无手机号</p>
            </div>
        </div>
        <div class="loinx_btn">
            <img src="../../assets/index/mb.png" alt="" style="width: 100%;" v-if="need == 2">
            <img src="../../assets/index/mb1.png" alt="" style="width: 100%;" v-if="need == 1">
            <div class="div1" v-if="need == 2">
                <p>嗨，亲爱的合作伙伴：</p>
                <p>很开心的告诉您，平台结算方式更新了。</p>
                <p>我们已与第三方支付平台“汇付”展开了成熟稳定的合作，我们强烈建议您进行结算方式变更。变更后您可享用：</p>
                <p style="margin-top: .6rem;"><span style="color: #1260FC">1.结 算：</span>T+1结算，运营收入可直接到您绑定的任意银行卡；</p>
                <p style="margin-top: .6rem;"><span style="color: #1260FC">2.线上退款：</span>永久免费开通价值300元/年的线上退款功能，享受快速、便捷的增值服务；</p>
                <p style="margin-top: .6rem;"><span style="color: #1260FC">3.安心充电：</span>可开通并享用除充电运营以外的增值收益；</p>
                <p style="margin-top: .6rem;"><span style="color: #1260FC">4.平台客服：</span>可不定时试用免费或优惠的平台客服服务，帮您接听一些用户来电。</p>
                <p style="margin-top: .6rem;"><span style="font-weight: 600;">开通方式1：</span>直接点击下方注册按钮，根据提醒操作即可（预计最多花费3-8分钟）</p>
                <p style="margin-top: .6rem;"><span style="font-weight: 600;">开通方式2：</span>联系您的业务经理或技术支持，辅助您开通</p>
            </div>
            <div class="div1" v-if="need == 1">
                <p style="color: red;">依据平台要求完成商户进件认证:</p>
                <p style="margin-top: .6rem;">1.商户收款使用第三方支付公司上海汇付的Adapay进行独立结算;</p>
                <p style="margin-top: .6rem;">2.上海汇付具有中国人民银行颁发的支付业务许可证，为10大支付品牌公司之一;</p>
                <p style="margin-top: .6rem;">3.商户类型可以为企业或个人，按商户提交申请资料;</p>
                <p style="margin-top: .6rem;">4.结算方式为T+1自动到系，无提现手续费，企业商户可选择对公或法人个人银行卡;</p>
                <p style="margin-top: .6rem;">5.每笔支付订单交易手续费为9‰.</p>
            </div>
            <div class="fl1 jcm">
                <div class="lo_btn fl1" @click="zc()">注册商户</div>
                <div class="lo_btn fl1" @click="inlet()">进入系统</div>
            </div>
        </div>
        <div style="height: 3rem;"></div>
    </div>
</template>

<script>
import { getIndex } from "@/api/index"
import { getSystz } from "@/api/sys"
import { getInlet } from "@/api/index"
export default {
    components: {},
    data() {
        return {
            key: '',
            int: null,
            endDate: '',
            listData: [{ title: '兴科物联 智慧充电' }],
            staDate: null,
            tem: '',
            name:'',
            need:'',
            url:'',
            phone:'',
            isMobile: false
        };
    },
    methods: {
        // 入口配置
        async inlet() {
            let data = await getInlet({ token: localStorage.getItem("token") });
            if (data.data.code == 1) {
                this.worktable = data.data.data.check_worktable;
                if (this.worktable == 1) {
                    this.$router.push({
                        path: "/index",
                        query: {
                            int: this.isMobile,
                            listData: this.listData,
                            tem: this.tem,
                        }
                    });
                    window.scrollTo(0, 0);
                }
                if (this.worktable == 0) {
                    this.$router.push({ path: "/menu" });
                }
            }
        },
        async get() {
            let data = await getSystz({ token: localStorage.getItem('token') });
            if (data.data.code == 1) {
                this.listData = data.data.data;
            }
        },
        async getDates() {
            let data = await getIndex({ token: localStorage.getItem("token"), start_time: this.staDate, end_time: this.endDate });
            if (data.data.code == 1) {
                let tempData = data.data.data;
                this.tem = tempData;
            }
        },
        iss() {
            const reg = /(Android|iPhone|iPad|Windows Phone)/i;
            const userAgent = navigator.userAgent;
            return reg.test(userAgent);
        },
        zc() {
            this.$router.push({ path: "/info" });
            window.scrollTo(0, 0);
        },
    },
    created() {
        this.name = this.$route.query.name;
        this.need = this.$route.query.need;
        this.url = this.$route.query.url;
        this.phone = this.$route.query.phone;
        this.staDate = this.$moment().startOf('month').format('YYYY-MM-DD');
        this.endDate = this.$moment().subtract(0, 'months').format('YYYY-MM-DD');
    },
    mounted() {
        this.getDates();
        this.get();
        this.iss();
        this.isMobile = this.iss();
    }
}
</script>

<style lang="less">
.loinx {
    position: relative;
    background: url(../../assets/index/bj.png) 0 0 no-repeat;
    background-size: 100% 100%;
    .div1{
        padding: 0 .8rem;
        font-size: 1.5rem;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none
        }
        p{
            line-height: 1.5;
        }
    }

    .loinx_top {
        padding: 2rem 2rem 0;
        z-index: 100;

        .loinx_bt {
            border-radius: .6rem;
            color: #fff;
            margin-left: .8rem;
            padding: .2rem .4rem;
            font-size: 1.2rem;
            background-color: #1260FC;
        }

        p {
            color: #666;
            margin-top: .8rem;
        }

        .loinx_t_img {
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            margin: 1rem;
        }
    }

    .loinx_btn {
        margin: 0 1.3rem 1.3rem;
        border-radius: .6rem;
        padding: 1rem 1rem 2rem;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;

        p {
            font-size: 1.5rem;
        }

        .jcm {
            justify-content: space-between;
            margin-top: 2rem;
        }

        .lo_btn {
            background-color: #1260FC;
            color: #fff;
            font-size: 1.6rem;
            justify-content: center;
            border-radius: .6rem;
            width: 46%;
            height: 4rem;
        }
    }
}
</style>